<template>
  <div v-loading="isLoading">
    <div class="searchBar">
      <el-form :inline="true">
        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.statusList"
            placeholder="状态"
            filterable
            multiple
            size="mini"
            style="width: 100px"
          >
            <el-option
              v-for="item in EnableStatusOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input
            clearable
            placeholder="请输入关键字"
            v-model="searchForm.keyword"
            size="mini"
          />
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="listData()"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="toUpload = true"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button
        style="margin-top: 1em"
        type="text"
        class="right-button"
        @click="showAdd()"
        >添加数据</el-button
      > -->
    <el-table
      :data="pageData.record"
      stripe
      style="width: 100%"
      border
      :default-sort="{ prop: 'id', order: 'ascending' }"
    >
      <el-table-column prop="id" sortable label="id" width="80" />

      <el-table-column label="图片" width="300">
        <template slot-scope="scope">
          <img :src="scope.row.url" style="width: 100%" />
        </template>
      </el-table-column>

      <!-- <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          {{
            findLabelByValue(EnableStatusOptions, scope.row.status)
          }}
        </template>
      </el-table-column> -->
      <el-table-column
        prop="createTime"
        width="180"
        sortable
        label="创建时间"
      />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button @click="showDesc(scope.row)" type="text" size="small">
            查看
          </el-button> -->
          <el-switch
            v-model="scope.row.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="0"
            :inactive-value="1"
            active-text="启用"
            inactive-text="禁用"
            @change="changeStatus($event, scope.row.id)"
          >
          </el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="pageData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <el-dialog :visible.sync="toUpload" width="400px" v-loading="uploading">
      <el-upload
        :with-credentials="true"
        :limit="1"
        action="+"
        :http-request="uploadImg"
        drag
        multiple
        :show-file-list="false"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          只能上传jpg/png文件，且不超过500kb
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>
<script>
// import { Message } from "element-ui";

import { EnableStatusOptions, findLabelByValue } from "../util/constant";
export default {
  name: "bannerView",
  data() {
    return {
      EnableStatusOptions: [],
      uploading: false,
      isLoading: false,
      isShowDesc: false,
      searchForm: {
        keyword: "",
        statusList: [],
        current: 1,
        size: 10,
      },
      pageData: [],
      isStatus: false,
      toUpload: false,
    };
  },
  methods: {
    listData() {
      this.isLoading = true;
      this.postRequest("/admin/banner/list", this.searchForm).then((res) => {
        if (res.data.status == 200) {
          this.pageData = res.data.data;
        }
        this.isLoading = false;
      });
    },
    findLabelByValue(e, v) {
      return findLabelByValue(e, v);
    },
    showInfo(user) {
      console.log(user);
    },
    currentChange(current) {
      this.searchForm.current = current;
      this.listData();
    },
    sizeChange(size) {
      this.searchForm.size = size;
      this.listData();
    },
    changeStatus(status, id) {
      this.getRequest(`/admin/banner/change/${id}/${status}`).then(() => {
        this.$message.success("操作成功");
      });
    },
    uploadImg(data) {
      this.uploading = true;
      this.uploadFileRequest("/admin/banner/upload", { file: data.file }).then(
        (res) => {
          this.uploading = false;
          if (res.data.status == 200) {
            this.toUpload = false;
            this.listData();
          }
        }
      );
    },
  },
  created() {
    this.EnableStatusOptions = EnableStatusOptions;
    this.listData();
  },
};
</script>
<style>
.searchBar {
  width: 60%;
  float: left;
  margin: 1em 0 0.1em 1em;
}
</style>
