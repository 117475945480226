<template>
  <div>
    <el-row>
      <el-col :span="4">
        <el-menu :default-active="$route.path" :router="true">
          <el-menu-item index="/statics">
            <i class="el-icon-menu"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="/user">
            <i class="el-icon-user"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="/info">
            <i class="el-icon-s-check"></i>
            <span slot="title">实名信息管理</span>
          </el-menu-item>
          <el-menu-item index="/hospital">
            <i class="el-icon-office-building"></i>
            <span slot="title">医院管理</span>
          </el-menu-item>
          <el-menu-item index="/department">
            <i class="el-icon-postcard"></i>
            <span slot="title">科室管理</span>
          </el-menu-item>
           <el-menu-item index="/article">
            <i class="el-icon-edit"></i>
            <span slot="title">文章管理</span>
          </el-menu-item>
          <el-menu-item index="/banner">
            <i class="el-icon-s-unfold"></i>
            <span slot="title">轮播图管理</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="20">
        <div class="user-item">
          <el-popover
            popper-class="popper"
            trigger="hover"
            placement="top"
            width="150"
            v-model="visible"
          >
            <div class="action-item first-item">修改密码</div>
            <div class="action-item last-item" @click="logout">退出登陆</div>
            <div class="user-title" slot="reference">
              {{ loginUser.name }}<i class="el-icon-arrow-down"></i>
            </div>
          </el-popover>
        </div>

        <router-view />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { Message } from "element-ui";
export default {
  name: "indexPage",
  data() {
    return {
      loginUser: {},
      visible: false,
    };
  },
  methods: {
    logout() {
      this.getRequest("/user/logout", { id: this.loginUser.id });
      localStorage.removeItem("loginUser");
      localStorage.removeItem("tokenName");
      localStorage.removeItem("tokenValue");
      this.$router.push("/");
      Message({
        message: "退出成功",
        type: "success",
      });
    },
  },
  created() {
    let user = JSON.parse(localStorage.getItem("loginUser"));
    if (!user) {
      Message({
        message: "请登录",
        type: "error",
      });
      this.logout();
    }
    this.loginUser = user;
  },
};
</script>
<style scoped>
.user-item {
  position: absolute;
  right: 0;
}
.action-item {
  width: 100%;
  text-align: center !important;
  cursor: pointer;
}
.first-item{
  padding-bottom: 5px;

}
.last-item{
  padding-top: 5px;
  border-top: 1px solid black;
}
.user-title {
  font-size: 14px;
  width: 100px;
}
</style>
