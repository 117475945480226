<template>
  <div v-loading="isLoading">
    <div class="searchBar">
      <el-form :inline="true">
        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.status"
            placeholder="状态"
            filterable
            size="mini"
            style="width: 100px"
            @change="listData"
          >
            <el-option
              v-for="item in EnableStatusOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input
            clearable
            placeholder="请输入关键字"
            v-model="searchForm.keyword"
            size="mini"
          />
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="listData()"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button
        style="margin-top: 1em"
        type="text"
        class="right-button"
        @click="showAdd()"
        >添加数据</el-button
      > -->
    <el-table
      :data="pageData.record"
      stripe
      style="width: 100%"
      border
      :default-sort="{ prop: 'id', order: 'descending' }"
    >
      <el-table-column prop="id" sortable label="id" width="80" />

      <el-table-column prop="title" label="标题" width="300" />
      <!-- <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          {{
            findLabelByValue(EnableStatusOptions, scope.row.status)
          }}
        </template>
      </el-table-column> -->
      <el-table-column label="创建用户id" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="toUser(scope.row.createUser)">{{
            scope.row.createUser
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="createUserName" label="创建用户名" width="200" />
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span style="color: #5cb87a" v-if="scope.row.status == 1">启用</span>
          <span style="color: #f56c6c" v-if="scope.row.status == 0">禁用</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        width="180"
        sortable
        label="创建时间"
      />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button @click="showDesc(scope.row)" type="text" size="small">
            查看
          </el-button> -->

          <el-button type="text" @click="showContent(scope.row)"
            >查看内容</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="pageData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      custom-class="header"
      :title="articleDetail.createUserName+'：'"
      :visible.sync="isShowContent"
      width="40%"
      top="0"
    >
      <el-card class="box-card" style="margin-bottom: 10px">
        <div slot="header" class="clearfix">
          <span>{{ articleDetail.title }}</span>
        </div>
        <div class="content" v-html="articleDetail.content"></div>
      </el-card>
      <el-switch
        v-model="articleDetail.status"
        active-color="#13ce66"
        inactive-color="#ff4949"
        :active-value="1"
        :inactive-value="0"
        active-text="启用"
        inactive-text="禁用"
        @change="changeStatus"
      >
      </el-switch>
    </el-dialog>
  </div>
</template>
<script>
// import { Message } from "element-ui";

import { EnableStatusOptions, findLabelByValue } from "../util/constant";
export default {
  name: "articleView",

  data() {
    return {
      EnableStatusOptions: [],
      isLoading: false,
      isShowDesc: false,
      articleDetail: {},
      searchForm: {
        keyword: "",
        status: "",
        current: 1,
        size: 10,
      },
      pageData: [],
      isStatus: false,
      toUpload: false,
      action: "",
      isShowContent: false,
    };
  },
  methods: {
    showContent(article) {
      console.log(article);
      this.articleDetail = article;
      this.isShowContent = true;
    },
    listData() {
      this.isLoading = true;
      this.postRequest("/admin/article/list", this.searchForm).then((res) => {
        if (res.data.status == 200) {
          this.pageData = res.data.data;
        }
        this.isLoading = false;
      });
    },
    findLabelByValue(e, v) {
      return findLabelByValue(e, v);
    },
    showInfo(user) {
      console.log(user);
    },
    currentChange(current) {
      this.searchForm.current = current;
      this.listData();
    },
    sizeChange(size) {
      this.searchForm.size = size;
      this.listData();
    },
    changeStatus(status) {
      this.getRequest(
        `/admin/article/change/${this.articleDetail.id}/${status}`
      ).then(() => {
        this.$message.success("操作成功");
      });
    },
    toUser(id) {
      this.$router.push({
        name: "user",
        params: {
          id: +id,
        },
      });
    },
  },
  created() {
    this.EnableStatusOptions = EnableStatusOptions;
    this.listData();
  },
};
</script>
<style>
.searchBar {
  width: 60%;
  float: left;
  margin: 1em 0 0.1em 1em;
}
</style>
<style scoped>
.content >>> img {
  width: 100%;
}
.header {
  padding: 0 !important;
}
</style>
