const UserRoleOptions = [
    {
        label: "用户",
        value: 1
    },
    {
        label: "医生",
        value: 2
    }
]

const EnableStatusOptions = [
    {
        label: "启用",
        value: 0
    },
    {
        label: "禁用",
        value: 1
    }
]

const UserStatusOptions = [
    {
        label: "未实名",
        value: 0
    },
    {
        label: "审核中",
        value: 1
    },
    {
        label: "已实名",
        value: 2
    },
    {
        label: "实名失败",
        value: 3
    }
]

const HospitalGradeOptions = [
    {
        label: "一级丙等",
        value: 1
    },
    {
        label: "一级乙等",
        value: 2
    },
    {
        label: "一级甲等",
        value: 3
    },
    {
        label: "二级丙等",
        value: 4
    },
    {
        label: "二级乙等",
        value: 5
    },
    {
        label: "二级甲等",
        value: 6
    },
    {
        label: "三级丙等",
        value: 7
    },
    {
        label: "三级乙等",
        value: 8
    },
    {
        label: "三级甲等",
        value: 9
    },
]



export { UserRoleOptions, UserStatusOptions, HospitalGradeOptions, EnableStatusOptions }

export function findLabelByValue(option, value) {
    return option.find((item) => item.value == value).label
}