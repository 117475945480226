<template>
  <div v-loading="isLoading">
    <div class="searchBar">
      <el-form :inline="true">
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="searchForm.keyword"
            size="mini"
            clearable
          >
            <el-select
              v-model="searchForm.searchType"
              placeholder="搜索"
              slot="prepend"
              filterable
              size="mini"
              style="width: 90px"
            >
              <el-option
                v-for="item in searchTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true" style="margin-top: -30px; width: 290px">
        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.statusList"
            placeholder="状态"
            filterable
            multiple
            size="mini"
            style="width: 100px"
          >
            <el-option
              v-for="item in UserStatusOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.roleList"
            placeholder="身份"
            filterable
            multiple
            size="mini"
            style="width: 100px"
          >
            <el-option
              v-for="item in UserRoleOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="listData()"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button
        style="margin-top: 1em"
        type="text"
        class="right-button"
        @click="showAdd()"
        >添加数据</el-button
      > -->
    <el-table
      :data="pageData.record"
      stripe
      style="width: 100%"
      border
      :default-sort="{ prop: 'id', order: 'ascending' }"
    >
      <el-table-column prop="id" sortable label="id" width="80" />

      <el-table-column
        prop="nickname"
        label="昵称"
        width="150"
        show-overflow-tooltip
      />
      <el-table-column label="头像" width="100">
        <template slot-scope="scope">
          <img :src="scope.row.avatarUrl" style="width: 100%" />
        </template>
      </el-table-column>
      <el-table-column label="身份" width="100">
        <template slot-scope="scope">
          {{ findLabelByValue(UserRoleOptions, scope.row.role) }}
        </template>
      </el-table-column>

      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span
            :class="{
              red: scope.row.status == 3,
              green: scope.row.status == 2,
            }"
          >
            {{ findLabelByValue(UserStatusOptions, scope.row.status) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        width="180"
        sortable
        label="创建时间"
      />
      <el-table-column
        prop="openid"
        show-overflow-tooltip
        width="180"
        sortable
        label="openid"
      />
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-button @click="showDesc(scope.row)" type="text" size="small">
            查看
          </el-button> -->
          <!-- <el-switch
            v-model="value"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-value="1"
            inactive-value="0"
              active-text="启用"
            inactive-text="禁用"

          >
          </el-switch> -->
          <!-- <el-button
            type="text"
            style="color: #f56c6c"
            size="small"
            v-if="scope.row.status == 0"
            @click="changeStatus(scope.row)"
            :disabled="isStatus"
          >
            禁用
          </el-button>
          <el-button
            type="text"
            size="small"
            v-else
            @click="changeStatus(scope.row)"
            :disabled="isStatus"
          >
            启用
          </el-button> -->
          <el-button
            type="text"
            size="small"
            v-if="scope.row.status != 0"
            @click="showInfo(scope.row)"
          >
            查看实名信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="pageData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
// import { Message } from "element-ui";

import {
  UserRoleOptions,
  UserStatusOptions,
  findLabelByValue,
} from "../util/constant";
export default {
  name: "userView",

  data() {
    return {
      UserRoleOptions: [],
      UserStatusOptions: [],
      isLoading: false,
      isShowDesc: false,
      searchForm: {
        keyword: "",
        statusList: [],
        roleList: [],
        searchType: 1,
        current: 1,
        size: 10,
      },
      pageData: [],
      isStatus: false,
      searchTypeOption: [
        {
          label: "昵称",
          value: 1,
        },
        {
          label: "openid",
          value: 2,
        },
        {
          label: "用户id",
          value: 6,
        },
      ],
    };
  },
  methods: {
    listData() {
      var reg = new RegExp("^[0-9]*$");
      if (
        this.searchForm.searchType == 6 &&
        !reg.test(this.searchForm.keyword)
      ) {
        this.$message({
          message: "id只能为数字",
          type: "error",
        });
        return;
      }
      this.isLoading = true;
      this.postRequest("/admin/user/list", this.searchForm).then((res) => {
        if (res.data.status == 200) {
          this.pageData = res.data.data;
        }
        this.isLoading = false;
      });
    },
    findLabelByValue(e, v) {
      return findLabelByValue(e, v);
    },
    showInfo(user) {
      let role = "user";
      if (user.role == 2) {
        role = "doctor";
      }
      this.$router.push({
        name: "info",
        params: {
          role: role,
          id: +user.id,
        },
      });
    },
    currentChange(current) {
      this.searchForm.current = current;
      this.listData();
    },
    sizeChange(size) {
      this.searchForm.size = size;
      this.listData();
    },
    changeStatus(item) {
      this.isStatus = true;
      this.getRequest(`/admin/user/changeStatus/${item.id}`).then((res) => {
        item.status = res.data.data;
        this.isStatus = false;
        this.$message.success("操作成功");
      });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.searchForm.keyword = this.$route.params.id;
      this.searchForm.searchType = 6;
    }
    this.UserRoleOptions = UserRoleOptions;
    this.UserStatusOptions = UserStatusOptions;
    this.listData();
  },
};
</script>
<style>
.searchBar {
  width: 60%;
  float: left;
  margin: 1em 0 0.1em 1em;
}
</style>
<style scoped>
.red {
  color: #f56c6c;
}
.green {
  color: #67c23a;
}
</style>
