<template>
  <div v-loading="isLoading">
    <div class="searchBar">
      <el-form :inline="true">
        <el-form-item>
          <!-- <el-select
              clearable
              v-model="searchForm.statusList"
              placeholder="状态"
              filterable
              multiple
              size="mini"
              style="width: 100px"
            >
              <el-option
                v-for="item in EnableStatusOptions"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
        </el-form-item>

        <el-form-item>
          <el-select
            clearable
            v-model="searchForm.gradeList"
            placeholder="等级"
            filterable
            multiple
            size="mini"
            style="width: 110px"
          >
            <el-option
              v-for="item in HospitalGradeOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-input
            clearable
            placeholder="请输入关键字"
            v-model="searchForm.keyword"
            size="mini"
          />
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="listData()"
            >搜索</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="isAdd = true"
            >添加医院</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button
        style="margin-top: 1em"
        type="text"
        class="right-button"
        @click="showAdd()"
        >添加数据</el-button
      > -->
    <el-table
      :data="pageData.record"
      stripe
      style="width: 100%"
      border
      :default-sort="{ prop: 'id', order: 'ascending' }"
    >
      <el-table-column prop="id" sortable label="id" width="80" />

      <el-table-column
        prop="name"
        label="名字"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column label="等级" width="100">
        <template slot-scope="scope">
          {{ findLabelByValue(HospitalGradeOptions, scope.row.grade) }}
        </template>
      </el-table-column>

      <el-table-column
        prop="phones"
        show-overflow-tooltip
        width="180"
        sortable
        label="联系方式"
      />
      <el-table-column
        prop="addressDetail"
        show-overflow-tooltip
        width="180"
        label="地址"
      />
      <el-table-column
        prop="createTime"
        width="180"
        sortable
        label="创建时间"
      />

      <!-- <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="scope.row.status != 0"
            @click="showInfo(scope.row)"
            :disabled="isStatus"
          >
            查看实名信息
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="pageData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <el-dialog title="添加医院" :visible.sync="isAdd" v-loading="isLoading" width="40%">
      <el-form label-width="80px" label-position="left">
        <el-form-item label="名字">
          <el-input type="text" style="width: 80%" v-model="addForm.name"/>
        </el-form-item>
        <el-form-item label="地址">
          <el-cascader
          v-model="addForm.provinceArray"
          :options="provinceList"
          :props="caProps"
          filterable
        ></el-cascader>
        </el-form-item>
        <el-form-item label="等级">
          <el-select
            clearable
            v-model="addForm.grade"
            placeholder="等级"
            filterable
          >
            <el-option
              v-for="item in HospitalGradeOptions"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址详情">
          <el-input type="text" style="width: 80%" v-model="addForm.addressDetail"/>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input type="text" style="width: 80%" v-model="addForm.phones"/>
        </el-form-item>
        
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="isAdd = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { Message } from "element-ui";

import {
  EnableStatusOptions,
  findLabelByValue,
  HospitalGradeOptions,
} from "../util/constant";
export default {
  name: "hospitalView",

  data() {
    return {
      caProps: {
        children: "cityList",
        value: "id",
        label: "name",
      },
      provinceList: [],
      pId: [],
      isAdd: false,
      EnableStatusOptions: [],
      HospitalGradeOptions: [],
      isLoading: false,
      isShowDesc: false,
      searchForm: {
        keyword: "",
        statusList: [],
        gradeList: [],
        current: 1,
        size: 10,
      },
      pageData: [],
      isStatus: false,
      isRole: false,
      addForm:{
        provinceArray: [],
        name: "",
        addressDetail: "",
        grade: 1,
        phones: ""
      }
    };
  },
  methods: {
    add() {
      let param = {...this.addForm}
      param.provinceId = this.addForm.provinceArray[0]
      param.cityId = this.addForm.provinceArray[1]
      this.isLoading = true;
      
      this.postRequest("/admin/hospital/add", param).then(res=>{
      this.isLoading = false
        if(res.data.status == 200) {
          this.$message.success("添加成功")
          this.isAdd = false
          this.listData()
        }
      })
    },
    listData() {
      this.isLoading = true;
      this.postRequest("/admin/hospital/list", this.searchForm).then((res) => {
        if (res.data.status == 200) {
          this.pageData = res.data.data;
        }
        this.isLoading = false;
      });
    },
    findLabelByValue(e, v) {
      return findLabelByValue(e, v);
    },
    showInfo(user) {
      console.log(user);
    },
    currentChange(current) {
      this.searchForm.current = current;
      this.listData();
    },
    sizeChange(size) {
      this.searchForm.size = size;
      this.listData();
    },
    changeStatus(item) {
      this.isStatus = true;
      this.getRequest(`/admin/user/changeStatus/${item.id}`).then((res) => {
        item.status = res.data.data;
        this.isStatus = false;
        this.$message.success("操作成功");
      });
    },
    listProvince() {
      this.getRequest("/province/list").then((res) => {
        this.provinceList = res.data.data;
      });
    },
  },
  created() {
    this.EnableStatusOptions = EnableStatusOptions;
    this.HospitalGradeOptions = HospitalGradeOptions;
    this.listData();
    this.listProvince();
  },
};
</script>
<style>
.searchBar {
  width: 60%;
  float: left;
  margin: 1em 0 0.1em 1em;
}
</style>
