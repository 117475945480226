<template>
  <div v-loading="isLoading">
    <div class="searchBar">
      <el-form :inline="true" style="width: 290px">
        <el-form-item>
          <el-input
            placeholder="请输入内容"
            v-model="searchForm.keyword"
            size="mini"
            clearable
          >
            <el-select
              v-model="searchForm.searchType"
              placeholder="搜索"
              slot="prepend"
              filterable
              size="mini"
              style="width: 90px"
            >
              <el-option
                v-for="item in searchTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
      </el-form>
      <el-form :inline="true" style="margin-top: -30px; width: 290px">
        <el-form-item>
          <el-select
            v-model="searchForm.statusList"
            placeholder="状态"
            filterable
            multiple
            size="mini"
            style="width: 100px"
            clearable
          >
            <el-option
              v-for="item in statusOption"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="mini" type="primary" @click="listData()"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.record"
      stripe
      style="width: 100%"
      border
      :default-sort="{ prop: 'id', order: 'ascending' }"
    >
      <el-table-column prop="id" sortable label="id" width="80" />
      <el-table-column prop="userId" label="用户id" width="80" />
      <el-table-column
        prop="name"
        label="姓名"
        width="100"
        show-overflow-tooltip
      />
      <el-table-column prop="phone" label="电话" width="120" />
      <el-table-column
        prop="idCardNum"
        label="身份证"
        width="200"
        show-overflow-tooltip
      />
      <el-table-column
        prop="createTime"
        width="180"
        sortable
        label="创建时间"
      />
      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <span
            :class="{
              text_green: Number(scope.row.status) === 1,
              text_red: Number(scope.row.status) === 2,
            }"
          >
            {{
              statusOption.find((item) => item.value == scope.row.status).label
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showUserInfo(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="pageData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="用户实名信息"
      :visible.sync="showUserInfoOption.isShow"
      width="30%"
    >
      <el-row>
        <el-col :span="6"> 姓名： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.name }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"> 年龄： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.birthday | ageFilter }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"> 电话： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.phone }}
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6"> 身份证： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.idCardNum }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"> 身份证图片： </el-col>
        <el-col :span="9">
          <el-image
            style="width: 100px; height: 100px"
            :src="showUserInfoOption.img1"
            :preview-src-list="this.showUserInfoOption.imgList"
          >
          </el-image>
        </el-col>
        <el-col :span="9">
          <el-image
            style="width: 100px; height: 100px; margin-left: 20px"
            :src="showUserInfoOption.img2"
            :preview-src-list="this.showUserInfoOption.imgList"
          >
          </el-image>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"> 提交时间： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.createTime }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"> 审核时间： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.updateTime }}
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6"> 状态： </el-col>
        <el-col :span="18">
          <span
            :class="{
              text_green: Number(this.showUserInfoOption.data.status) === 1,
              text_red: Number(this.showUserInfoOption.data.status) === 2,
            }"
          >
            {{
              statusOption.find(
                (item) => item.value == this.showUserInfoOption.data.status
              )
                ? statusOption.find(
                    (item) => item.value == this.showUserInfoOption.data.status
                  ).label
                : ""
            }}
          </span>
        </el-col>
      </el-row>
      <el-row v-if="this.showUserInfoOption.data.status == 2">
        <el-col :span="6"> 失败原因： </el-col>
        <el-col :span="18">
          {{ this.showUserInfoOption.data.failReason }}
        </el-col>
      </el-row>
      <div v-if="this.showUserInfoOption.data.status == 0">
        <br />
        <el-row>
          <el-col :offset="6">
            <el-button type="success" @click="approve(1)" size="mini"
              >通过</el-button
            >
            <el-button type="danger" @click="toDisApprove()" size="mini"
              >不通过</el-button
            >
          </el-col>
        </el-row>
      </div>
      <el-dialog :visible.sync="showFailReason" append-to-body width="40%">
        <el-form label-width="80">
          <el-form-item label="失败原因">
            <el-input v-model="failReason" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit" @click="approve(2)">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { Message } from "element-ui";

export default {
  name: "user-info",
  props: {
    id: Number,
  },
  data() {
    return {
      isLoading: false,
      searchForm: {
        keyword: "",
        statusList: [],
        current: 1,
        size: 10,
        searchType: 3,
      },
      showUserInfoOption: {
        isShow: false,
        data: {},
      },
      failReason: "",
      showFailReason: false,
      pageData: [],
      statusOption: [
        {
          label: "审核中",
          value: 0,
        },
        {
          label: "审核成功",
          value: 1,
        },
        {
          label: "审核失败",
          value: 2,
        },
      ],
      searchTypeOption: [
        {
          label: "姓名",
          value: 3,
        },
        {
          label: "身份证",
          value: 4,
        },
        {
          label: "电话",
          value: 5,
        },
        {
          label: "用户id",
          value: 6,
        },
      ],
    };
  },
  methods: {
    approve(status) {
      if (status == 2 && !this.failReason) {
        Message({
          message: "失败原因不能为空",
          type: "error",
        });
        return;
      }
      this.isLoading = true;
      this.showUserInfoOption.isShow = false;
      let data = {
        infoId: this.showUserInfoOption.data.id,
        userId: this.showUserInfoOption.data.userId,
        status: status,
        failReason: this.failReason,
      };
      this.postRequest("/admin/approve/user", data).then((res) => {
        this.failReason = "";
        this.isLoading = false;
        this.showFailReason = false;
        if (res.data.status == 200) {
          this.listData();
        }
      });
    },
    toDisApprove() {
      this.showFailReason = true;
    },
    search() {
      this.searchForm.current = 1;
      this.listData();
    },
    listData() {
      var reg = new RegExp("^[0-9]*$");

      if (
        this.searchForm.searchType == 6 &&
        !reg.test(this.searchForm.keyword)
      ) {
        this.$message({
          message: "id只能为数字",
          type: "error",
        });
        return;
      }
      this.isLoading = true;
      this.postRequest("/admin/userinfo/list", this.searchForm).then((res) => {
        if (res.data.status == 200) {
          this.pageData = res.data.data;
        }
        this.isLoading = false;
      });
    },
    currentChange(current) {
      this.searchForm.current = current;
      this.listData();
    },
    sizeChange(size) {
      this.searchForm.size = size;
      this.listData();
    },
    showUserInfo(userInfo) {
      this.showUserInfoOption.isShow = true;
      this.showUserInfoOption.data = userInfo;
      this.showUserInfoOption.imgList =
        this.showUserInfoOption.data.idCardUrls.split(",");
      this.showUserInfoOption.img1 = this.showUserInfoOption.imgList[0];
      this.showUserInfoOption.img2 = this.showUserInfoOption.imgList[1];
    },
  },
  created() {
    if (this.id != 0) {
      (this.searchForm.searchType = 6), (this.searchForm.keyword = this.id);
    }
    this.listData();
  },
  filters: {
    ageFilter(date) {
      let age = 0;
      let bir_year = dayjs(date).year();
      let now_year = dayjs().year();
      let bir_mon = dayjs(date).month();
      let now_mon = dayjs().month();
      age = now_year - bir_year;
      if (bir_mon > now_mon) {
        age--;
      }
      return age;
    },
  },
};
</script>

<style>
.text_red {
  color: #f56c6c;
}
.text_green {
  color: #5cb87a;
}
</style>
