<template>
  <div>
    <div id="form">
      <h2>民易医系统登陆</h2>
      <el-form :model="loginForm" :rules="rules" ref="loginForm">
        <el-form-item prop="phone">
          <el-input
            placeholder="请输入手机号"
            prefix-icon="el-icon-user"
            v-model="loginForm.phone"
            maxlength="11"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="请输入密码"
            prefix-icon="el-icon-lock"
            v-model="loginForm.password"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            style="width: 100%"
            v-loading="isLoading"
            type="primary"
            @click="login('loginForm')"
            >立即登陆</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { Message } from 'element-ui';
export default {
  name: "loginPage",
  data() {
    return {
      loginForm: {
        phone: "",
        password: "",
      },
      isLoading: false,
      rules: {
        phone: [
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    login(formName) {
      this.isLoading = true;
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.postRequest("/admin/user/login", this.loginForm)
          .then((result) => {
            this.isLogin = false;
            if (result.data.status == 200) {
              localStorage.setItem(
                "loginUser",
                JSON.stringify(result.data.data)
              );
              localStorage.setItem("tokenName", result.data.data.tokenName);
              localStorage.setItem("tokenValue", result.data.data.tokenValue);

              this.$router.push("/index");
              Message({
                message: '登陆成功',
                type: 'success'
              })
            }
            this.isLoading = false;
          })
          .catch((this.isLoading = false));
      });
    },
  },
};
</script>
<style scoped>
#form {
  width: 30%;
  margin: auto;
  text-align: center;
  margin-top: 15%;
}
</style>
