import axios from 'axios'
import router from '../router'
import {Message} from 'element-ui'

axios.defaults.timeout = 30 * 1000; 

let url = 'http://localhost:8888/'
 url = 'http://119.8.55.213:8888'
axios.defaults.baseURL = url

export default url;

axios.interceptors.request.use(config => {
    const tokenName = localStorage.getItem("tokenName");
    const tokenValue = localStorage.getItem("tokenValue");
    if (tokenName && tokenValue) {
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers[tokenName] = tokenValue;
    }
    return config;
}, err => {
    Message({
        message: '请求超时',
        type: 'error',
    })
    return Promise.resolve(err);
})
axios.interceptors.response.use(data => {
    if (data.status && data.status == 200 && data.data.msg != '') {
        if (data.data.status != 200) {
            data.data.msg += '>ᯅ<'
            Message({
                message: data.data.msg,
                type: 'error',
            })

        }
    }
    if (data.data.status == 403) {
        localStorage.removeItem('loginUser')
        localStorage.removeItem('tokenName')
        localStorage.removeItem('tokenValue')
        router.push("/")
    }
    return data;
}, err => {
    Message({
        message: '服务器被吃了⊙﹏⊙∥',
        type: 'error',
    })
    return Promise.resolve(err);
})



let base = '';

export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    });
}
export const uploadFileRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}
export const deleteRequest = (url) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`
    });
}
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params
    });
}