<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" :lazy="true">
      <el-tab-pane label="用户实名信息" name="user">
        <user-info-view :id="id"/>
      </el-tab-pane>
      <el-tab-pane label="医生实名信息" name="doctor">
        <doctor-info-view :id="id"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import DoctorInfoView from "../components/DoctorInfoView.vue";
import UserInfoView from "../components/UserInfoView.vue";

export default {
  components: {
    DoctorInfoView: DoctorInfoView,
    UserInfoView: UserInfoView,
  },
  data() {
    return {
      activeName: "user",
      id: 0,
    };
  },
  methods: {},
  created(){
    if(this.$route.params.role) {
      this.activeName = this.$route.params.role
    }
    if(this.$route.params.id) {
     this.id=this.$route.params.id
    }
  }
};
</script>

<style></style>
