<template>
  <div v-loading="isLoading">
    <el-row :gutter="20" class="text-container">
         <el-col :span="6">
        <div id="userNum" class="echarts"></div>
      </el-col>
      <el-col :span="6">
        <div id="dailyActive" class="echarts"></div>
      </el-col>
      <el-col :span="6">
        <div id="order" class="echarts"></div>
      </el-col>
      <el-col :span="6">
        <div id="newUser" class="echarts"></div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <div>
          <el-statistic title="全部用户(医生/用户)">
            <template slot="formatter"
              >{{ +userNumData.userNum + +userNumData.doctorNum }}, ({{
                userNumData.userNum
              }}/{{ userNumData.doctorNum }})
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-statistic title="日活用户(医生/用户)">
            <template slot="formatter"
              >{{
                dailyActiveUserData.doctorDailyActiveNum +
                dailyActiveUserData.userDailyActiveNum
              }}, ({{ dailyActiveUserData.doctorDailyActiveNum }}/{{
                dailyActiveUserData.userDailyActiveNum
              }})
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-statistic title="今日订单">
            <template slot="formatter"
              >{{ dailyOrderData.newOrderNum }}, (已接单：{{
                dailyOrderData.getOrderNum
              }})
            </template>
          </el-statistic>
        </div>
      </el-col>
      <el-col :span="6">
        <div>
          <el-statistic title="今日新用户(医生/用户)">
            <template slot="formatter"
              >{{ newUserNumData.newUserNum + newUserNumData.newDoctorNum }}, ({{
                newUserNumData.newDoctorNum
              }}/{{ newUserNumData.newUserNum }})
            </template>
          </el-statistic>
        </div>
      </el-col>
    </el-row>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "staticsView",
  data() {
    return {
      isLoading: false,
      dailyActiveUserData: {},
      dailyActiveUserOption: {},
      dailyOrderData: {},
      dailyOrderOption: {},
      userNumData: {},
      userNumOption: {},
      newUserNumData: {},
      newUserNumOption: {},
    };
  },
  methods: {
    drawCharts() {
      this.dailyActiveUserOption = {
        tooltip: {
          //提示框组件
          trigger: "item", //item数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: "{b} : {c} <br/>百分比 : {d}%", //{a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
        },

        series: [
          {
            type: "pie",
            data: [
              {
                value: +this.dailyActiveUserData.userDailyActiveNum,
                name: "用户",
              },
              {
                value: +this.dailyActiveUserData.doctorDailyActiveNum,
                name: "医生",
              },
            ],
          },
        ],
      };
      let orderData  = [
        {
            value: +this.dailyOrderData.newOrderNum - +this.dailyOrderData.getOrderNum,
            name: "未接单订单"
        },
        {
            value: +this.dailyOrderData.getOrderNum,
            name: "接单订单"
        },
      ]
      console.log(this.newUserNumData)
      let newUserData = [
         {
            value: +this.newUserNumData.newUserNum,
            name: "新用户"
        },
        {
            value: +this.newUserNumData.newDoctorNum,
            name: "新医生"
        },
      ]
      let userData = [
         {
            value: +this.userNumData.userNum,
            name: "用户"
        },
        {
            value: +this.userNumData.doctorNum,
            name: "医生"
        },
      ]
      this.dailyOrderOption = JSON.parse(JSON.stringify(this.dailyActiveUserOption))
      this.newUserNumOption = JSON.parse(JSON.stringify(this.dailyActiveUserOption))
      this.userNumOption = JSON.parse(JSON.stringify(this.dailyActiveUserOption))
      
      this.dailyOrderOption.series[0].data = orderData
      this.newUserNumOption.series[0].data = newUserData
      this.userNumOption.series[0].data = userData

      // 基于准备好的dom，初始化echarts实例
      var dailyActive = this.$echarts.init(document.getElementById("dailyActive"));
      var order = this.$echarts.init(document.getElementById("order"));
      var newUser = this.$echarts.init(document.getElementById("newUser"));
      var userNum = this.$echarts.init(document.getElementById("userNum"));

      // 指定图表的配置项和数据

      // 使用刚指定的配置项和数据显示图表。
      dailyActive.setOption(this.dailyActiveUserOption);
      order.setOption(this.dailyOrderOption)
      userNum.setOption(this.userNumOption)
      newUser.setOption(this.newUserNumOption)
    },
    getStaticsData() {
      this.isLoading = true;
      this.getRequest("/admin/statics/list").then((res) => {
        this.dailyActiveUserData = res.data.data.dailyActiveUserData;
        this.dailyOrderData = res.data.data.dailyOrderData;
        this.userNumData = res.data.data.userNumData;
        this.newUserNumData = res.data.data.newUserNumData;
        this.drawCharts();
        this.isLoading = false;
      });
    },
  },
  created() {
    this.getStaticsData();
  },
};
</script>

<style scoped>
.text-container {
  margin-top: 10%;
}
.echarts {
  width: 100%;
  height: 200px;
  margin: auto;
}
</style>
