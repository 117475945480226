import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import IndexView from '../views/IndexView.vue'
import UserView from '../views/UserView.vue'
import HospitalView from '../views/HospitalView.vue'
import DepartmentView from '../views/DepartmentView.vue'
import BannerView from '../views/BannerView.vue'
import InfoManageView from '../views/InfoManageView.vue'
import StaticsView from '../views/StaticsView.vue'
import ArticleView from '../views/ArticleView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView,
    redirect: '/statics',
    children:[
      {
        path: '/user',
        name: 'user',
        component: UserView
      },
      {
        path: '/hospital',
        name: 'hospital',
        component: HospitalView
      },
      {
        path: '/banner',
        name: 'banner',
        component: BannerView
      },
      {
        path: '/department',
        name: 'department',
        component: DepartmentView
      },
      {
        path: '/info',
        name: 'info',
        component: InfoManageView
      },
      {
        path: '/statics',
        name: 'statics',
        component: StaticsView
      },
      {
        path: '/article',
        name: 'article',
        component: ArticleView
      },

    ]

    
  }
]

const router = new VueRouter({
  routes
})

export default router
