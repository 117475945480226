<template>
  <div>
    <div>
      <el-button size="mini" type="primary" @click="toAdd(-1)"
        >添加父科室</el-button
      >
      <el-tree
        :props="{ label: 'name' }"
        :data="treeData"
        show-checkbox
        node-key="id"
        default-expand-all
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span>{{ node.label }}</span>
          <span>
            <el-button type="text" size="mini" @click="() => toAdd(data.id)">
              添加科室
            </el-button>
            <el-button
              v-if="data.status == 1"
              type="text"
              size="mini"
              @click="() => changeStatus(data.id, 0)"
            >
              启用
            </el-button>
            <el-button
              v-else
              style="color: red"
              type="text"
              size="mini"
              @click="() => changeStatus(data.id, 1)"
            >
              禁用
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>

    <el-dialog :visible.sync="showAdd" width="350px">
      <el-form label-width="50px">
        <el-form-item label="名称">
          <el-input size="mini" v-model="addDepname" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="addDep">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      treeData: {},
      parentId: -1,
      showAdd: false,
      addDepname: "",
      depId: 0,
    };
  },

  methods: {
    toAdd(pId) {
      this.depId = 0;
      this.addDepname = "";
      this.parentId = pId;
      this.showAdd = true;
    },
    addDep() {
      let data = {
        name: this.addDepname,
        parentId: this.parentId,
      };
      if (this.depId != 0) {
        data.id = this.depId;
      }
      this.postRequest("/admin/department/save", data).then(() => {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.showAdd = false;
        this.listData();
      });
    },
    changeStatus(id, status) {
      this.getRequest(`/admin/department/change/${id}/${status}`).then(() => {
         this.$message({
          message: "操作成功",
          type: "success",
        });
        this.listData();
      });
    },

    listData() {
      this.isLoading = true;
      this.getRequest("/admin/department/list").then((res) => {
        if (res.data.status == 200) {
          this.treeData = res.data.data;
        }
        this.isLoading = false;
      });
    },
  },
  created() {
    this.listData();
  },
};
</script>

<style></style>
